/* Content */
.mainSection {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f0f8ff;
}
.contentBoxCenter {
  width: 80%;
  padding-left: 2em;
}
.contentBoxCenter h1 {
  width: 80%;
  margin-left: 45%;
  margin-right: 45%;
  align-items: center;
  font-size: 3rem;
  font-family: "Poppins", sans-serif;
}
.contentBoxCenter p {
  width: 100%;
  margin-left: 10%;
  margin-right: 30%;
  justify-content: center;
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
}
.contentBox {
  width: 40%;
  padding-left: 2em;
}

.contentBox h1 {
  font-size: 3rem;
  color: #301924;
  justify-content: space-evenly;
  align-content: center;
}

.contentBox h2 {
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
}

/* SVG Graphics */
.imageContainerCenter {
  width: 80%;
  padding-left: 2em;
}
.imageContainerCenter .svgGraphic {
  margin-left: 10%;
}

/* Button center */
.btnBoxCenter {
  margin-left: 50%;
  display: flex;
}

.btnBox {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 2em;
}

.btn {
  border: 2px solid transparent;
  border-radius: 2.5em;
  background-image: linear-gradient(#fff, #fff),
    radial-gradient(circle at bottom right, purple, orange);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.readMore {
  display: inline-block;
  font-size: 1.2rem;
  padding: 0.5em 1.5em;
  text-decoration: none;
  color: #301924;
}

.contactMore {
  display: inline-block;
  font-size: 1.2rem;
  padding: 0.5em 1.5em;
  text-decoration: none;
  color: #301924;
}

/* Header */

header {
  /* background-color: var(--mainColour); */
  /* linear-gradient(#fff, #fff),
        radial-gradient(circle at bottom right, purple, orange); */
  background-image: radial-gradient(circle at top right, #a3cbf3, #e3fbca);
  padding: 0.8em;
}
.container {
  width: 95%;
  /* margin: 0 auto; */
  /* max-width: 1200px; */
}
.container-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logoContainerHeader {
  align-items: center;
  width: 25%;
}
.list {
  display: flex;
  flex-direction: row;
  margin-left: 0%;
  align-items: center;
  /* width: 40%; */
  /* align-items: center; */
  /* margin-right: 40%; */
}
.icons {
  width: 20%;
  display: flex;
  flex-direction: row;
}
.headerLogo {
  width: 40%;
  margin-left: 30%;
}

.listItem {
  margin-left: 2em;
  color: #dcdcdc;
  font-size: 1.5rem;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

.activeItem {
  margin-left: 2em;
  color: #ff9500;
  font-size: 1.5rem;
  text-decoration: underline;
  font-family: "Poppins", sans-serif;
}

.inactiveItem {
  margin-left: 2em;
  color: #2a0101;
  font-size: 1.5rem;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

.icon {
  color: #421212;
  font-size: 1.5rem !important;
  margin-right: 1em;
  margin-left: 1.5em;
}

/* Footer */
footer {
  /* position: relative;
  bottom: 0; */
  /* background-color: #38a5f4; */
  background-image: radial-gradient(circle at top right, #e3fbca, #a3cbf3);
  width: 100%;
  /* padding: 0.1em; */
}
.copyright {
  /* height: 60%; */
  width: 25%;
  margin-left: 1%;
}
.footerContactMail {
  width: 20%;
  display: flex;
  margin-left: 10%;
  flex-direction: row;
}
.footerContactPh {
  width: 20%;
  display: flex;
  margin-left: 10%;
  flex-direction: row;
}
.footerIcons {
  width: 40%;
  display: flex;
  margin-left: 10%;
  flex-direction: row;
}
.logoContainerFooter {
  text-align: center;
  color: #162020;
  align-items: center;
  width: 100%;
}

.footerLogo {
  width: 40%;
}

@media screen and (max-width: 900px) {
  html {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .contentBox p {
    font-size: 10px;
  }
  .contentBoxCenter p {
    font-size: 10px;
  }
  .contentBoxCenter h1 {
    font-size: 12px;
  }
  .contentBoxCenter h2 {
    font-size: 11px;
  }
  .contentBoxCenter h3 {
    font-size: 10px;
  }
  .contentBox h1 {
    font-size: 12;
  }
  .contentBox h2 {
    font-size: 11px;
  }
  .contentBox h3 {
    font-size: 10px;
  }
  .mainSection {
    flex-direction: column;
    margin-top: 1em;
  }
  .contentBox {
    width: 90%;
    margin: 0 auto;
  }
  .footerIcons {
    flex-direction: column;
  }
  .icons {
    flex-direction: column;
  }
  .list {
    flex-direction: column;
  }

  .contentBox h1 {
    font-size: 2rem;
  }
  .contentBox h2 {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 400px) {
  .contentBox p {
    font-size: 10px;
  }
  .contentBoxCenter p {
    font-size: 8px;
  }
  .contentBoxCenter h1 {
    font-size: 10px;
  }
  .contentBoxCenter h2 {
    font-size: 9px;
  }
  .contentBoxCenter h3 {
    font-size: 8px;
  }
  .contentBox h1 {
    font-size: 10px;
  }
  .contentBox h2 {
    font-size: 9px;
  }
  .contentBox h3 {
    font-size: 8px;
  }
  .mainSection {
    flex-direction: column;
  }
  .footerIcons {
    flex-direction: column;
  }
  .icons {
    flex-direction: column;
  }
  .list {
    flex-direction: column;
  }
  .container-flex {
    align-items: center;
    flex-direction: column;
  }
}
